<template>

  <div>

    <PartitionCover cnTitle="企业动态" enTitle="Dynamic" backgroundImageFileName="dynamic.jpg" />

    <div class="CommonContentWidth">

      <!-- <div class="CommonTabTitle">企业动态 Dynamic</div> -->

      <div class="list">

        <div class="item" v-for="(item, index) in result" :key="'article' + index">
          <router-link :to="{ path: '/article/' + item.id }" target="_blank" >
          
          <div class="cover"><img :src="item.image" /></div>
          <div class="info">
            <div class="title">{{item.title}}</div>
            <div class="exp">{{item.info}}</div>
          </div>
          
          </router-link>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import articleIndex from '../../data/articleIndex'
import PartitionCover from "@/components/PartitionCover.vue";
export default {
  name: 'Dynamic',
  components: {
    PartitionCover
  },
  created() {
      const at = articleIndex['dynamic'].slice()
      this.result = at.reverse("id")
  },
  data() {
    return {
        result:[
        ]
    };
  },
}
</script>
<style lang="scss" scoped>
  .list{
    width: 100%;
    margin-top: 50px;

    .item{
      width: 100%;
      display: flow-root;
      margin-bottom: 50px;

      .cover{
        width: 380px;
        float: left;
        img{width: 100%;}
      }
      .info{
        width: calc(100% - 410px);
        float: right;
      }
      .title{
        font-size: 24px;
        width: 100%;
        color: #0b314f;
        height: 60px;
        overflow:hidden; 
        text-overflow:ellipsis;
        display:-webkit-box; 
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2; 
      }
      .exp{
            font-size: 14px;
            color: #0b314f;
            margin-top: 10px;
            overflow:hidden; 
            text-overflow:ellipsis;
            display:-webkit-box; 
            -webkit-box-orient:vertical;
            -webkit-line-clamp:1; 
      }
    }

  }

@media screen and (max-width: 850px) {
  .list{
    width: 100%;

    .item{
      width: 100%;
      display: flow-root;
      margin-bottom: 50px;

      .cover{
        width: 100%;
        float: left;
        margin-bottom: 10px;
      }
      .info{
        width: 100%;
        float: right;
        margin-bottom: 30px;
      }
    }

  }
}

</style>